export const grommetTheme = {
  spacing: 24,
  global: {
    colors: {
      brand: {
        light: "#007481",
        dark: "#007481",
      },
      background: {
        dark: "#111111",
        light: "#E5E8EB",
      },
      "background-back": {
        dark: "#111111",
        light: "#F5F8FB",
      },
      "background-front": {
        dark: "#222222",
        light: "#D5D8DB",
      },
      "background-contrast": {
        dark: "#FFFFFF11",
        light: "#11111111",
      },
      text: {
        dark: "#E5E8EB",
        light: "#827D7B",
      },
      "text-strong": {
        dark: "#00586F",
        light: "#00586F",
      },
      "text-weak": {
        dark: "#CCCCCC",
        light: "#00AFAA",
      },
      "text-xweak": {
        dark: "#999999",
        light: "selected-background",
      },
      border: {
        dark: "#444444",
        light: "#827D7B",
      },
      control: "brand",
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "accent",
      "selected-text": "#E5E8EB",
      "status-critical": "#FF4040",
      "status-warning": "#FFAA15",
      "status-ok": "#00C781",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "accent",
      teamParallax: {
        padding: "100px",
        "align-items": "center",
      },
      button: {
        dark: "#00586F",
        light: "#00586F",
      },
      "button!": "",
      focus: {
        light: "#00AFAA",
        dark: "#00AFAA",
      },
      primary: {
        dark: "#00586F",
        light: "#00586F",
      },
      "primary!": "",
      secondary: {
        dark: "#00AFAA",
        light: "#00AFAA",
      },
      "secondary!": "",
      accent: {
        dark: "#96785C",
        light: "#96785C",
      },
      "accent!": "",
      darkAccent: {
        dark: "#827D7B",
        light: "#827D7B",
      },
      "darkAccent!": "",
    },
    font: {
      family: "Readex Pro",
      size: "18px",
      height: "24px",
      maxWidth: "432px",
    },
  },
  button: {
    border: {
      width: "3px",
      radius: "0px",
    },
    padding: {
      vertical: "4px",
      horizontal: "22px",
    },
    color: "selected-text",
  },
  checkBox: {
    check: {
      radius: "0px",
    },
    toggle: {
      radius: "0px",
      size: "48px",
    },
    size: "24px",
  },
  menu: {
    background: "selected-text",
  },
  radioButton: {
    size: "24px",
    check: {
      radius: "0px",
    },
  },
  formField: {
    border: {
      color: "accent",
      error: {
        color: "status-critical",
      },
      position: "inner",
      // side: "bottom",
    },
    content: {
      pad: "small",
    },
    disabled: {
      background: {
        color: "status-disabled",
        // opacity: "medium",
      },
    },
    error: {
      color: "status-critical",
      margin: {
        vertical: "xsmall",
        horizontal: "small",
      },
    },
    help: {
      color: "text-weak",
      margin: {
        start: "small",
      },
    },
    info: {
      color: "text-xweak",
      margin: {
        vertical: "xsmall",
        horizontal: "small",
      },
    },
    label: {
      margin: {
        vertical: "xsmall",
        horizontal: "small",
      },
      color: "accent",
    },
    margin: {
      bottom: "small",
    },
    round: "0px",
  },
  text: {
    font: {
      family: "Readex Pro",
    },
  },
  header: { height: "300px", background: { color: "button" } },
}

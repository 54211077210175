import React from "react"
import { Footer as GrommetFooter, Text, Box, Button } from "grommet"
import { LinkedinOption, FacebookOption, Instagram } from "grommet-icons"
// @ts-ignore
import Modal from "./modal/Modal"

const openInNewTab = url => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer")
  if (newWindow) newWindow.opener = null
}
export const Footer = () => {
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = React.useState(false)
  const [termsConditionsOpen, setTermsConditionsOpen] = React.useState(false)
  const onPrivacyClose = () => setPrivacyPolicyOpen(false)
  const onTermsClose = () => setTermsConditionsOpen(false)

  return (
    <GrommetFooter
      // align="center"
      direction="row"
      justify="center"
      gap="medium"
      flex={false}
      fill="horizontal"
      background={{ color: "border" }}
      pad="medium"
      wrap
      // responsive
    >
      <Box
        align="center"
        justify="center"
        direction="row"
        gap="small"
        pad="xsmall"
        wrap
        responsive
      >
        <Box onClick={() => setTermsConditionsOpen(true)}>
          <Text color="background" size="small">
            Termos e Condições
          </Text>
          {termsConditionsOpen && (
            <Modal onClose={onTermsClose} fileName="Termos e Condições" />
          )}
        </Box>
        <Box onClick={() => setPrivacyPolicyOpen(true)}>
          <Text size="small" color="background">
            Política de Privacidade
          </Text>
          {privacyPolicyOpen && (
            <Modal
              onClose={onPrivacyClose}
              fileName="Política de Privacidade"
            />
          )}
        </Box>
        <Text color="background" size="small">
          © {new Date().getFullYear()} Mons Consulting
        </Text>
      </Box>
      <Box
        align="center"
        justify="center"
        flex
        direction="row"
        gap="small"
        wrap
        responsive
      >
        <Button
          plain
          onClick={() =>
            openInNewTab("https://www.linkedin.com/company/mons-consulting")
          }
          icon={<LinkedinOption color="background" />}
        />

        <Button
          plain
          onClick={() =>
            openInNewTab("https://www.facebook.com/monsconsulting.lda")
          }
          icon={<FacebookOption color="background" />}
        />
        <Button
          plain
          onClick={() =>
            openInNewTab("https://www.instagram.com/mons.consulting/")
          }
          icon={<Instagram color="background" />}
          target="_blank"
        />
      </Box>
    </GrommetFooter>
  )
}

import React from "react"
import { Box, Layer } from "grommet"
// import { FormClose } from "grommet-icons"
import { useStaticQuery, graphql } from "gatsby"

const Modal = ({ onClose, fileName }) => {
  const data = useStaticQuery(graphql`
    query ModalQuery {
      allMarkdownRemark {
        nodes {
          frontmatter {
            title
          }
          html
        }
      }
    }
  `)
  const { allMarkdownRemark } = data
  const file = allMarkdownRemark.nodes.find(
    element => element.frontmatter.title === fileName
  )
  const { frontmatter, html } = file
  return (
    <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
      <Box pad="medium" gap="small" overflow="auto">
        <h1>{frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Box>
    </Layer>
  )
}

export default Modal

import React from "react"
import { Text } from "grommet"

export const Heading = ({ children, color, props }) => {
  return (
    <Text size="xlarge" weight="bold" margin="xsmall" color={color}>
      {children}
    </Text>
  )
}

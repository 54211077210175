import React from "react"
import { Box } from "grommet"
import { Heading } from "."

const Section = ({ children, heading, background, color, ...rest }) => {
  return (
    <Box fill="horizontal" pad="large" background={background} {...rest}>
      <Heading color={color}>{heading}</Heading>
      {/* <Box pad={{ horizontal: "large", vertical: "medium" }}> */}
      <Box pad="xsmall">{children}</Box>
    </Box>
  )
}

export default Section

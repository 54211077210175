import React from "react"
import {
  Box,
  ResponsiveContext,
  Menu,
  Nav,
  Text,
  Header as GrommetHeader,
} from "grommet"
import { Menu as MenuIcon } from "grommet-icons"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { StaticImage } from "gatsby-plugin-image"
import Link from "../Link"

const StyledAnchorLink = styled(AnchorLink)`
  color: #e5e8eb;
`

export const Header = () => {
  return (
    <Box elevation="small">
      <GrommetHeader background="brand">
        <Box
          direction="row"
          align="center"
          justify="between"
          background={{
            color: "button",
          }}
          fill="horizontal"
          pad={{ horizontal: "medium", vertical: "small" }}
          elevation="medium"
        >
          <Box align="center" justify="start">
            <Link to="/">
              <StaticImage
                src="../../assets/mons_logo.png"
                alt="Mons logo"
                placeholder="tracedSVG"
                layout="constrained"
                height={45}
              />
            </Link>
          </Box>
          <ResponsiveContext.Consumer>
            {size =>
              size === "small" ? (
                <Box justify="end" pad="medium">
                  <Menu
                    a11yTitle="Navigation Menu"
                    dropProps={{ align: { top: "bottom", right: "right" } }}
                    icon={<MenuIcon color="selected-text" />}
                    items={[
                      {
                        label: "Sobre Nós",
                        href: "#about",
                      },
                      {
                        label: "Equipa",
                        href: "#team",
                      },
                      {
                        label: "Contactos",
                        href: "#contact",
                      },
                    ]}
                  />
                </Box>
              ) : (
                <Nav
                  align="center"
                  flex={false}
                  direction="row"
                  justify="center"
                  gap="medium"
                  pad="small"
                >
                  <StyledAnchorLink href="#about" color="background">
                    <Text>Sobre Nós</Text>
                  </StyledAnchorLink>
                  <StyledAnchorLink href="#team" color="background">
                    <Text>Equipa</Text>
                  </StyledAnchorLink>
                  <StyledAnchorLink href="#contact" color="background">
                    <Text>Contactos</Text>
                  </StyledAnchorLink>
                </Nav>
              )
            }
          </ResponsiveContext.Consumer>
        </Box>
      </GrommetHeader>
    </Box>
  )
}

export default Header

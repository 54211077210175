import React from "react"
import styled from "styled-components"
import { Box } from "grommet"
import { FormDown } from "grommet-icons"
import AnchorLink from "react-anchor-link-smooth-scroll"

const StyledBox = styled(Box)`
  height: 87vh;
`
//TODO: Add AnchorLink component
const Hero = ({ data }) => {
  return (
    <StyledBox
      background={`url(${data.parallax_hero.edges[0].node.childImageSharp.gatsbyImageData.images.fallback.src})`}
      fill
      justify="end"
      align="center"
    >
      {/* <Image
        fit="contain"
        src={src}
        // alignSelf="end"
      /> */}
      <Box margin="medium" animation="pulse">
        <AnchorLink href="#about">
          <FormDown size="large" color="secondary" />
        </AnchorLink>
      </Box>
    </StyledBox>
  )
}
export default Hero
